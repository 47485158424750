import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue'


const routes = [
  {
    path: '/:code?',
    name: 'Index',
    meta: {
      title: '防伪码查询',
      keepAlive: true,
      footer: true,
      backgroundColor: '#fff'
    },
    component: Index
  }
]

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  routes
})

export default router
